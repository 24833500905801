<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- birth date -->
        <b-col md="12">
          <b-form-group label="Pilih Roles">
            <v-select
              v-model="localData"
              label="name"
              :options="localOptions"
            />
          </b-form-group>
        </b-col>
        <!--/ birth date -->
        <b-col v-if="localData.id === 'operator'" md="12">
          <b-form-group label="Pilih DPC">
            <b-form-select
              v-model="dpn_dpc_id"
              :options="optionsStrukturOrganisasi"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="simpan"
          >
            <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BFormSelect,
} from 'bootstrap-vue'
import { api } from '@/hap-sia/setup'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BSpinner,
    vSelect,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: ['masterUserRole', 'masterStrukturOrganisasi'],
  data() {
    return {
      btnSimpanSpinnerShow: false,
      localData: this.masterUserRole,
      localOptions: [
        { id: 'verifikator', name: 'Verifikator' },
        { id: 'operator', name: 'Operator' },
        { id: 'admin', name: 'Administrator' },
        { id: 'member', name: 'Member' },
        { id: 'public', name: 'Publik' },
        { id: 'user', name: 'User' },
        { id: 'percetakan', name: 'Percetakan' },
      ],
      dpn_dpc_id: null,
      optionsStrukturOrganisasi: [{ value: null, text: '--Pilih DPN/DPC--' }],
    }
  },
  async mounted() {
    for (let i = 0; i < this.masterStrukturOrganisasi.length; i += 1) {
      this.optionsStrukturOrganisasi.push({
        value: this.masterStrukturOrganisasi[i].id,
        text: this.masterStrukturOrganisasi[i].nama,
      })
    }
    if (this.masterUserRole.dpn_dpc_id) {
      this.dpn_dpc_id = this.masterUserRole.dpn_dpc_id
    }
  },
  methods: {
    async simpan() {
      this.btnSimpanSpinnerShow = true
      try {
        const tmpData = {
          user_id: this.$route.params.id,
          role_id: this.localData.id,
          dpn_dpc_id: this.dpn_dpc_id,
        }
        const results = await api().patch('user/role', tmpData)
        console.log(results)
        this.btnSimpanSpinnerShow = false
      } catch (error) {
        this.btnSimpanSpinnerShow = false
        this.makeToast('danger', 'Error', error.response.data.message, true)
      }
      this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
