<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>
      <detail-general
        v-if="masterUser"
        :master-user="masterUser.data"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Roles</span>
      </template>

      <detail-roles
        v-if="masterUser"
        :master-user-role="masterUser.data.role"
        :master-struktur-organisasi="masterStrukturOrganisasi.data"
      />
    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <detail-password />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { getCacheableApi, api } from '@/hap-sia/setup'
import DetailGeneral from './DetailGeneral.vue'
import DetailPassword from './DetailPassword.vue'
import DetailRoles from './DetailRoles.vue'

export default {
  components: {
    BTabs,
    BTab,
    DetailGeneral,
    DetailPassword,
    DetailRoles,
  },
  data() {
    return {
      masterUser: null,
      options: {},
      masterStrukturOrganisasi: null,
    }
  },
  async mounted() {
    const cacheApi = await getCacheableApi()
    try {
      this.masterStrukturOrganisasi = await cacheApi.get('master/struktur-organisasi')
    } catch (error) {
      alert(error.response.data.message)
      this.loadingMessage = 'Something went wrong. Please refresh browser'
    }
    const tmpUserId = this.$route.params.id
    if (!tmpUserId) {
      this.masterUser = {
        id: null,
        name: null,
        email: null,
        password: null,
        username: null,
      }
    } else {
      try {
        const user = await api().get(`user/${tmpUserId}`)
        this.masterUser = user
        this.masterUser.data.role.dpn_dpc_id = this.masterUser.data.dpn_dpc_id
      } catch (error) {
        this.loadingMessage = 'Something went wrong. Please refresh browser'
      }
    }
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
