<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group label="Nama Lengkap" label-for="user-namalengkap">
            <b-form-input
              v-model="localData.name"
              placeholder="Nama Lengkap"
              name="namalengkap"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="E-mail" label-for="user-email">
            <b-form-input
              v-model="localData.email"
              name="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Username" label-for="user-username">
            <b-form-input
              v-model="localData.username"
              name="username"
              placeholder="Username"
              :disabled="1"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="simpan"
          >
            <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import { api } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: ['masterUser'],
  data() {
    return {
      btnSimpanSpinnerShow: false,
      localData: this.masterUser,
      optionsLocal: null,
    }
  },
  methods: {
    async simpan() {
      this.btnSimpanSpinnerShow = true
      try {
        const tmpData = {
          id: this.$route.params.id,
          name: this.localData.name,
          email: this.localData.email,
        }
        const results = await api().patch('user', tmpData)
        console.log(results)
        this.btnSimpanSpinnerShow = false
      } catch (error) {
        this.btnSimpanSpinnerShow = false
        this.makeToast('danger', 'Error', error.response.data.message, true)
      }
      this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
